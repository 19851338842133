import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../layouts/LandingLayout";

export const LandingPage = () => {
	return (
		<LandingLayout>
			<Hero
				title="Zvyšte tržby a efektivitu expedice zásilek"
				subtitle="Inovativní řešení pro automatizaci logistiky v eCommerce. Od komunikace s dopravci až po zajištění zážitku zákazníka."
				image="/hero/box.jpg"
				ctaText="Již brzy!"
				ctaLink="/"
			/>
		</LandingLayout>
	);
};
