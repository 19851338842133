import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { theme } from "./theme";
import { LandingPage } from "./pages/LandingPage";

export const App = () => (
	<Router>
		<ChakraProvider theme={theme}>
			<ColorModeScript
				initialColorMode={theme.config.initialColorMode}
				type="localStorage"
			/>
			<Routes>
				<Route index path={"/"} element={<LandingPage />} />
			</Routes>
		</ChakraProvider>
	</Router>
);

export default App;
